import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import './main.css';
import { Elm } from './Main.elm';
import logger from './logger'

let app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    version: APP_VERSION,
    environment: NODE_ENV || 'development'
  }
});

let subscribeToPort = (portName, callback) => {
  if (app.ports && app.ports[portName]) {
    app.ports[portName].subscribe(callback)
  }
  else {
    logger.error(`Failed subscribing to port: ${portName}`)
  }
}

let sendToPort = (portName, data) => {
  if (app.ports && app.ports[portName]) {
    app.ports[portName].send(data)
  }
  else {
    logger.error(`Failed sending data to port: ${portName}`)
  }
}

subscribeToPort('sendMail', ([fullname, telephone, needs]) => {
  let xhr = new XMLHttpRequest();
  xhr.open('POST', 'https://us-central1-pdv-orleans.cloudfunctions.net/sendMail', true)
  xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
  xhr.onreadystatechange = () => {
    let status = xhr.status
    sendToPort('onMailSent', (status >= 200 && status < 400))
  }

  let data = JSON.stringify({fullname, telephone, needs})
  xhr.send(data);
})
